<template>
  <div class="user">
    <div v-title="'个人中心'"></div>
    <div class="header">
      <div class="header_avatar">
        <el-avatar icon="el-icon-user-solid" :size="50"></el-avatar>
      </div>
      <div class="header_mid">
        <div style="font-weight: bold; margin-top: 3%">{{ user.username }}</div>
        <div style="font-size: 15px">手机号:{{ user.phone }}</div>
      </div>
      <div class="header_btn">
        <el-button
          @click="updateUser"
          size="small"
          type="primary"
          round
          icon="el-icon-edit"
          >修改信息</el-button
        >
      </div>
    </div>
    <div class="search">
      <el-input
        v-model="searchs.value"
        placeholder="搜索收件人/投递人/快件单号"
      ></el-input>
      <div class="search_btn">
        <el-button @click="search" type="primary" round size="small"
          >搜 索</el-button
        >
      </div>
    </div>
    <div
      class="infinite-list-wrapper search_results"
      id="search_results"
      style="overflow: auto"
    >
      <ul class="list">
        <li v-for="item in recordList" class="list-item">
          <div class="item_title">
            <div class="left_title">单号：{{ item.record_no }}</div>
            <div class="right_title" :class="setColor(item)">
              {{ item.sort | sortFilter }}
            </div>
          </div>
          <div class="items">取件地址:{{ item.address }}</div>
          <div class="items">箱门编号:{{ item.box_name }}</div>
          <div class="items">投件人:{{ item.sender }}</div>
          <div class="items">取件人:{{ item.taker }}</div>
          <div class="items">投件人电话:{{ item.sender_phone }}</div>
          <div class="items">取件人电话:{{ item.taker_phone }}</div>
          <div class="items">投递时间:{{ item.sendTime }}</div>
          <div class="items" v-if="item.sort != 1 && item.sort != 2">
            取件时间:{{ item.takeTime }}
          </div>
        </li>
      </ul>
      <p v-if="loading">加载中...</p>
      <p v-if="noMore">没有更多了</p>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
$('input').on('click',function( ){
    $('#bottom').css({'position':'static'})
});
$('input').on('blur',function( ){
    $('#bottom').css({'position':'fixed'})
});
export default {
  data() {
    return {
      //用户个人信息，
      user: {},
      totalPage: 1,
      recordList: [],
      searchs: {
        pageSize: 10,
        pageNum: 1,
        value: "",
        phone: sessionStorage.getItem("phone"),
      },
      loading: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBottm, true);
  },
  created() {
    this.getUserMsg();
    this.getUseRecord();
  },
  computed: {
    noMore() {
      if (this.loading) {
        return false;
      } else {
        return this.searchs.pageNum >= this.totalPage;
      }
    },
  },
  methods: {
    scrollBottm() {
      // 滚动到页面底部时
      const el = document.getElementById("search_results");
      const windowHeight = window.screen.height;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const contentHeight = el.clientHeight;
      const toBottom = contentHeight - windowHeight - scrollTop;
      if (toBottom < 10 && !this.loading) {
        this.load(); //满足条件进行加载
      }
    },
    setColor(item) {
      switch (item.sort) {
        case 1:
          return "color1";
        case 2:
          return "color2";
        case 3:
          return "color3";
        case 4:
          return "color4";
      }
    },
    getUserMsg() {
      this.$axios
        .post("/cuser/getInfo/" + sessionStorage.getItem("phone"))
        .then((res) => {
          this.user = res.resultData || {};
        });
    },
    getUseRecord() {
      this.loading = true;
      this.$axios.post("/cuser/getRecord", this.searchs).then((res) => {
        if (res.code === 0) {
          this.totalPage = Math.ceil(
            res.resultData.totalCount / this.searchs.pageSize
          );
          this.recordList = [...this.recordList, ...res.resultData.list];
          this.loading = false;
        }
      });
    },
    load() {
      if (this.searchs.pageNum < this.totalPage) {
        this.searchs.pageNum++; //当前页码加一
        this.getUseRecord();
      }
    },
    updateUser() {
      this.$router.push({ path: "/register" });
    },
    search() {
      //搜索事件
      this.recordList = [];
      this.searchs.pageNum = 1; //重置页码为1
      this.getUseRecord(); //重新获取数据
    },
  },
  filters: {
    sortFilter(num) {
      const statusMap = {
        1: "未签收",
        2: "已超期",
        3: "已回收",
        4: "已签收",
      };
      return statusMap[num];
    },
  },
};
</script>

<style scoped lang="scss">
.user {
  // width: 100vw;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  .header {
    display: flex;
    align-items: start;
    background-color: #fff;
    padding: 5% 5%;
    box-sizing: border-box;
    .header_avatar {
      width: 15%;
    }
    .header_mid {
      width: 55%;
      box-sizing: border-box;
      padding-left: 10px;
      text-align: left;
    }
    .header_btn {
      width: 30%;
    }
  }
  .search {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 5%;
    .el-input {
      width: 75%;
      border-radius: 30px;
    }
  }
  .search_results {
    padding: 0 5%;
    background-color: #f5f5f5;
    .list {
      .list-item {
        list-style: none;
        background-color: #fff;
        margin: 15px 0px;
        padding-left: 10px;
        border-radius: 10px;
        .item_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 30px;
          .left_title {
            font-weight: bold;
          }
          .right_title {
            width: 23%;
            height: 100%;
            line-height: 200%;
            font-size: 14px;
            color: #fff;
            border-bottom-left-radius: 15px;
          }
          .color1 {
            background-color: #ffbf50;
          }
          .color2 {
            background-color: #ff6e6e;
          }
          .color3 {
            background-color: #5080ff;
          }
          .color4 {
            background-color: #808080;
          }
        }
        .items {
          text-align: left;
          padding: 2px 0;
        }
      }
      .list-item:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
